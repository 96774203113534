import React, { useState, useEffect, useContext } from 'react';
import Header from '../../components/Header';
import MobileNavbar from '../../components/MobileNavbar';
import Sidebar from '../../components/Sidebar';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import './index.css';
import { Button } from '@mui/material';
import moment from 'moment';
import { get_notes, create_note, delete_note } from '../../API/note';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from '../../contexts/AuthContext';
import { get_user, update_customer } from '../../API/user';
import { get_car_books } from '../../API/car_booking';
import Modal from '@mui/material/Modal';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { add } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import TripCard from '../../components/TripCard';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function UserDetail() {
  const { authAxios, user } = useContext(AuthContext);
  const { id } = useParams();
  const [pdfModal, setPdfModal] = useState();
  const isSuperValid = () => {
    return user.package?.name == 'Owner';
  };
  const navigate = useNavigate();
  const [customer, setCustomer] = useState();
  const [tab, setTab] = useState('trips');
  const [edit, setEdit] = useState(false);
  const [userDetail, setUserDetail] = useState({
    id: customer?.id,
    first_name: customer?.name.trim().split(' '[0]),
    last_name: customer?.name.trim().split(' '[1]),
    name: customer?.name,
    email: customer?.user.email,
    old_email: customer?.user.email,
    phone: customer?.phone,
    address: customer?.address,
    license_image_store: customer?.license_image,
    insurance_card_store: customer?.insurance_card,
    license_number: customer?.license_number,
    insurance_expiry_date:
      customer?.insurance_expiry_date || add(new Date(), { days: 2 }),
    birth_date: customer?.birth_date,
    postal_code: customer?.postal_code,
    insurance_policy: customer?.insurance_policy,
    insurance_company: customer?.insurance_company,
    username: customer?.user.username,
  });

  useEffect(() => {
    get_user(authAxios, id).then((res) => {
      if (res.status === 200) {
        setCustomer(res.data);
        setUserDetail({
          id: res.data.id,
          first_name: res.data.name.trim().split(' ')[0],
          last_name: res.data.name.trim().split(' ')[1],
          name: res.data.name,
          email: res.data.user.email,
          old_email: res.data.user.email,
          phone: res.data.phone,
          address: res.data.address,
          license_image_store: res.data.license_image,
          insurance_card_store: res.data.insurance_card,
          license_number: res.data.license_number,
          insurance_company: res.data.insurance_company,
          insurance_expiry_date: res.data.insurance_expiry_date,
          birth_date: res.data.birth_date,
          insurance_policy: res.data.insurance_policy,
          postal_code: res.data.postal_code,
          username: res.data.user.username,
        });
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  }, [edit]);
  const onChangeUserDetail = (e) => {
    setUserDetail({
      ...userDetail,
      [e.target.name]:
        [e.target.name] == 'license_image' || [e.target.name] == 'insurance_card' ? e.target.files[0] : e.target.value,
    });
  };

  const handleUpdateCustomer = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    const keys = Object.keys(userDetail).filter(
      (key) => key != 'license_image_store' && key != 'insurance_card_store'
    );
    for (let key of keys) {
      if (!userDetail[key]) {
        toast.error(key + ' is invalid.');
        return;
      } else {
        if (key == 'insurance_expiry_date' || key == 'birth_date') {
          formData.append(key, moment(userDetail[key]).format('YYYY-MM-DD'));
        } else formData.append(key, userDetail[key]);
      }
    }
    let res = await update_customer(authAxios, formData);
    if (res.status === 200) {
      toast.success('Updated Successfully');
      setEdit(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  function formatPhoneNumber(phoneNumberString) {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    } else {
      return phoneNumberString;
    }
  }

const [numPages, setNumPages] = useState(null);
	const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
		setNumPages(numPages);
	};

	const goToPrevPage = () =>
		setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

	const goToNextPage = () =>
		setPageNumber(
			pageNumber + 1 >= numPages ? numPages : pageNumber + 1,
		);

  const isPDFLicense = userDetail?.license_image_store?.split('.').reverse()[0] === 'pdf'
  const isPDFInsurance =  userDetail?.insurance_card_store?.split('.').reverse()[0] === 'pdf';

  const modelRender = () => {
    return (
    <Modal
      // open={pdfModal}
      open={Boolean(pdfModal)}
      onClose={() => setPdfModal(false)}
      aria-labelledby='conf_modal'
      aria-describedby='modal-modal-description'
    >
      <div className='confModal' style={{ width: 1000, height: 800}}>
        <nav>
          <button onClick={goToPrevPage}>Prev</button>
          <button onClick={goToNextPage}>Next</button>
          <p>
            Page {pageNumber} of {numPages}
          </p>
        </nav>

        <Document
          // file={userDetail?.license_image_store}
          file={pdfModal}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
        <div className='btns' style={{ marginTop: '20px' }}>
          <button
            onClick={() => setPdfModal(false)}
            className='secondary'
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>)
  }

  return (
    <div className='dashboard page'>
      <Sidebar page='all_users' />
      <div className='hero_content theme_content'>
        <MobileNavbar />
        <Header />
        <div className='pagewrapper'>
          <div className='booking_wrapper'>
            <div className='booking_header'>
              {isSuperValid() ? <h4>logout</h4> : <h4>User Detail</h4>}
              <div className='allUsers_header_actions'>
                {/* <button>Add Admin</button> */}
                <div></div>
                <button onClick={() => navigate('/add_person')}>
                  Create Client
                </button>
              </div>
            </div>
            {!isSuperValid() && (
              <div className=''>
                <div className='userBasicDetail'>
                  <div className='edit'>
                    <svg
                      onClick={() => setEdit((edit) => !edit)}
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke={edit ? '#4fc8e9' : 'currentColor'}
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10'
                      />
                    </svg>
                  </div>
                  <form onSubmit={(e) => handleUpdateCustomer(e)}>
                    <div className='detailContent'>
                      <div className='leftDetailContent'>
                        <div className='basicDetailRow'>
                          {edit && (
                            <Button
                              required
                              className='user_upload_btn edit_dropdown'
                              variant='contained'
                              component='label'
                            >
                              <svg
                                width='36'
                                height='36'
                                viewBox='0 0 36 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                  fill='#4FC8E9'
                                />
                                <path
                                  d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                  fill='#4FC8E9'
                                />
                              </svg>
                              {userDetail.license_image
                                ? userDetail.license_image.name
                                : 'Upload License Image'}
                              <input
                                type='file'
                                name='license_image'
                                id='license_image'
                                hidden
                                onChange={(e) => onChangeUserDetail(e)}
                              />
                            </Button>
                          )}
                          {!edit && !isPDFLicense && (
                            <img
                              className='user_detail_img'
                              src={userDetail?.license_image_store}
                              alt=""
                            ></img>
                          )}
                          {!edit && isPDFLicense && (
                            <>
                            <div className='userDetail__pdf_privew'>
                              <Document
                                file={userDetail?.license_image_store}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onClick={() => setPdfModal(userDetail?.license_image_store)}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                            </div>
                             <div className='userDetail__pdf_privew_bg'>PDF PREVIEW</div>
                             </>
                          )}
                        </div>
                        <div className='basicDetailRow' style={{ marginTop: '10px'}}>
                          {edit && (
                            <Button
                              required
                              className='user_upload_btn edit_dropdown'
                              variant='contained'
                              component='label'
                            >
                              <svg
                                width='36'
                                height='36'
                                viewBox='0 0 36 36'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <path
                                  d='M1.125 22.275C1.42337 22.275 1.70952 22.3935 1.9205 22.6045C2.13147 22.8155 2.25 23.1016 2.25 23.4V29.025C2.25 29.6217 2.48705 30.194 2.90901 30.616C3.33097 31.0379 3.90326 31.275 4.5 31.275H31.5C32.0967 31.275 32.669 31.0379 33.091 30.616C33.5129 30.194 33.75 29.6217 33.75 29.025V23.4C33.75 23.1016 33.8685 22.8155 34.0795 22.6045C34.2905 22.3935 34.5766 22.275 34.875 22.275C35.1734 22.275 35.4595 22.3935 35.6705 22.6045C35.8815 22.8155 36 23.1016 36 23.4V29.025C36 30.2185 35.5259 31.3631 34.682 32.207C33.8381 33.0509 32.6935 33.525 31.5 33.525H4.5C3.30653 33.525 2.16193 33.0509 1.31802 32.207C0.474106 31.3631 0 30.2185 0 29.025V23.4C0 23.1016 0.118526 22.8155 0.329505 22.6045C0.540483 22.3935 0.826631 22.275 1.125 22.275Z'
                                  fill='#4FC8E9'
                                />
                                <path
                                  d='M17.2039 2.5785C17.3084 2.47373 17.4326 2.39061 17.5693 2.33389C17.7059 2.27718 17.8525 2.24799 18.0004 2.24799C18.1484 2.24799 18.2949 2.27718 18.4316 2.33389C18.5683 2.39061 18.6924 2.47373 18.7969 2.5785L25.5469 9.3285C25.7582 9.53974 25.8769 9.82625 25.8769 10.125C25.8769 10.4237 25.7582 10.7103 25.5469 10.9215C25.3357 11.1327 25.0492 11.2514 24.7504 11.2514C24.4517 11.2514 24.1652 11.1327 23.9539 10.9215L19.1254 6.09075V25.875C19.1254 26.1734 19.0069 26.4595 18.7959 26.6705C18.585 26.8815 18.2988 27 18.0004 27C17.7021 27 17.4159 26.8815 17.205 26.6705C16.994 26.4595 16.8754 26.1734 16.8754 25.875V6.09075L12.0469 10.9215C11.9423 11.0261 11.8182 11.1091 11.6815 11.1657C11.5448 11.2223 11.3984 11.2514 11.2504 11.2514C11.1025 11.2514 10.956 11.2223 10.8194 11.1657C10.6827 11.1091 10.5585 11.0261 10.4539 10.9215C10.3493 10.8169 10.2664 10.6927 10.2098 10.5561C10.1532 10.4194 10.124 10.2729 10.124 10.125C10.124 9.97707 10.1532 9.8306 10.2098 9.69393C10.2664 9.55727 10.3493 9.4331 10.4539 9.3285L17.2039 2.5785Z'
                                  fill='#4FC8E9'
                                />
                              </svg>
                              {userDetail.insurance_card
                                ? userDetail.insurance_card.name
                                : 'Upload Insurance Card'}
                              <input
                                type='file'
                                name='insurance_card'
                                id='insurance_card'
                                hidden
                                onChange={(e) => onChangeUserDetail(e)}
                              />
                            </Button>
                          )}
                          {!edit && !isPDFInsurance && (
                            <img
                              className='user_detail_img'
                              src={userDetail?.insurance_card_store}
                              alt=""
                            ></img>
                          )}
                          {!edit && isPDFInsurance && (
                            <>
                            <div className='userDetail__pdf_privew'>
                              <Document
                                file={userDetail?.insurance_card_store}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onClick={() => setPdfModal(userDetail?.insurance_card_store)}
                              >
                                <Page pageNumber={pageNumber} />
                              </Document>
                            </div>
                             <div className='userDetail__pdf_privew_bg'>PDF PREVIEW</div>
                             </>
                          )}
                        </div>
                      </div>
                      <div className='rightDetailContent'>
                        <div className='basicDetailRow'>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.first_name && 'red',
                              }}
                            >
                              First name :
                            </label>
                            <input
                              type='text'
                              name='first_name'
                              value={userDetail?.first_name}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.last_name && 'red',
                              }}
                            >
                              Last name :
                            </label>
                            <input
                              type='text'
                              name='last_name'
                              value={userDetail?.last_name}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                        </div>
                        <div className='basicDetailRow'>
                          <div
                            className={`basicDetail info_row ${
                              edit && 'editing'
                            }`}
                          >
                            <label
                              style={{
                                color: !userDetail?.birth_date && 'red',
                              }}
                            >
                              Birth Date :
                            </label>
                            {edit && (
                              <div className='field'>
                                <div>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      label='Date'
                                      value={userDetail?.birth_date ? new Date(userDetail?.birth_date) : null}
                                      onChange={(newValue) => {
                                        setUserDetail({
                                          ...userDetail,
                                          birth_date: newValue,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className='time_date_picker'
                                          size='small'
                                          width={'100px'}
                                          // onKeyDown={(e) => e.preventDefault()}
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </div>
                            )}
                            {!edit && (
                              <input
                                type='text'
                                name='birth_date'
                                disabled={!edit}
                                value={
                                  userDetail?.birth_date
                                    ? moment(userDetail?.birth_date).format(
                                        'MM/DD/yyyy'
                                      )
                                    : ''
                                }
                              />
                            )}
                          </div>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.license_number && 'red',
                              }}
                            >
                              License No :
                            </label>
                            <input
                              type='text'
                              name='license_number'
                              value={userDetail?.license_number}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                        </div>
                        <div className='basicDetailRow'>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.phone && 'red',
                              }}
                            >
                              Phone no :
                            </label>
                            <input
                              type='text'
                              name='phone'
                              value={formatPhoneNumber(userDetail?.phone)}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.email && 'red',
                              }}
                            >
                              Email :
                            </label>
                            <input
                              type='text'
                              name='email'
                              value={userDetail?.email}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                        </div>
                        <div className='basicDetailRow'>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.address && 'red',
                              }}
                            >
                              Address :
                            </label>
                            <input
                              type='text'
                              name='address'
                              value={userDetail?.address}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.postal_code && 'red',
                              }}
                            >
                              Postal Code :
                            </label>
                            <input
                              type='text'
                              name='postal_code'
                              value={userDetail?.postal_code}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                        </div>
                        <div className='basicDetailRow'>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.insurance_company && 'red',
                              }}
                            >
                              Insurance Company :
                            </label>
                            <input
                              type='text'
                              name='insurance_company'
                              value={userDetail?.insurance_company}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                          <div className={`basicDetail  ${edit && 'editing'}`}>
                            <label
                              style={{
                                color: !userDetail?.insurance_policy && 'red',
                              }}
                            >
                              Insurance policy :
                            </label>
                            <input
                              type='text'
                              name='insurance_policy'
                              value={userDetail?.insurance_policy}
                              disabled={!edit}
                              onChange={(e) => onChangeUserDetail(e)}
                            />
                          </div>
                         
                        </div>
                        <div className='basicDetailRow'>
                          <div
                            className={`basicDetail info_row ${
                              edit && 'editing'
                            }`}
                          >
                            <label
                              style={{
                                color:
                                  !userDetail?.insurance_expiry_date && 'red',
                              }}
                            >
                              Insurance expiration :
                            </label>
                            {edit && (
                              // <LocalizationProvider
                              //   dateAdapter={AdapterDateFns}
                              // >
                              //   <DatePicker
                              //     // shouldDisableDate={(23, 24, 25) => true}
                              //     label='Date'
                              //     // readOnly
                              //     value={userDetail?.insurance_expiry_date}
                              //     onChange={(newValue) => {
                              //       setUserDetail({
                              //         ...userDetail,
                              //         insurance_expiry_date: newValue,
                              //       });
                              //     }}
                              //     renderInput={(params) => (
                              //       <TextField
                              //         className='time_date_picker'
                              //         size='small'
                              //         width={'100px'}
                              //         onKeyDown={(e) => e.preventDefault()}
                              //         required
                              //         {...params}
                              //       />
                              //     )}
                              //   />
                              // </LocalizationProvider>

                              <div className='field'>
                                <div>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                  >
                                    <DatePicker
                                      // shouldDisableDate={(23, 24, 25) => true}
                                      label='Date'
                                      // readOnly
                                      value={userDetail?.insurance_expiry_date ? new Date(userDetail?.insurance_expiry_date) : null}
                                      onChange={(newValue) => {
                                        setUserDetail({
                                          ...userDetail,
                                          insurance_expiry_date: newValue,
                                        });
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          className='time_date_picker'
                                          size='small'
                                          width={'100px'}
                                          // onKeyDown={(e) => e.preventDefault()}
                                          required
                                          {...params}
                                        />
                                      )}
                                    />
                                  </LocalizationProvider>
                                </div>
                              </div>
                            )}
                            {!edit && (
                              <input
                                type='text'
                                name='insurance_expiry_date'
                                disabled={!edit}
                                value={
                                  userDetail?.insurance_expiry_date
                                    ? moment(
                                        userDetail?.insurance_expiry_date
                                      ).format('MM/DD/yyyy')
                                    : ''
                                }
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='updateCustomer'>
                      {edit && <button type='submit'>Update</button>}
                    </div>
                  </form>
                </div>
                <div className='userAppDetails'>
                  <div className='userAppDetailsNav'>
                    <span
                      onClick={() => setTab('trips')}
                      className={`${tab === 'trips' && 'selected'}`}
                    >
                      Trips
                    </span>
                    <span
                      onClick={() => setTab('notes')}
                      className={`${tab === 'notes' && 'selected'}`}
                    >
                      Notes
                    </span>
                  </div>
                  <div className='userAppDetailsBody'>
                    {tab === 'trips' ? (
                      <Trips authAxios={authAxios} id={id} />
                    ) : tab === 'notes' ? (
                      <Notes authAxios={authAxios} id={id} user={user} />
                    ) : null}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {modelRender()}
    </div>
  );
}

const Trips = ({ authAxios, id }) => {
  const [trips, setTrips] = useState(null);

  const getTrips = () => {
    get_car_books(authAxios, undefined, id).then((res) => {
      if (res.status === 200) {
        setTrips(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  };

  useEffect(() => {
    getTrips();
  }, []);

  const today = new Date();
  return (
    <div className='tripsCards'>
      {!trips ? (
        <div>
          <CircularProgress color='primary' />
        </div>
      ) : trips.length === 0 ? (
        <div>No Trips Availavble</div>
      ) : (
        trips
          .sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(b.end_date) - new Date(a.end_date);
          })
          .map((trip) => <TripCard trip={trip}></TripCard>)
      )}
    </div>
  );
};

const Notes = ({ authAxios, id, user }) => {
  const [notes, setNotes] = useState(null);
  const [noteHeadLine, setNoteHeadLine] = useState('');
  const [noteContent, setNoteContent] = useState('');
  const [addNew, setAddNew] = useState(false);

  const getNotes = () => {
    get_notes(authAxios, id).then((res) => {
      if (res.status === 200) {
        setNotes(res.data);
      } else {
        toast.error(Object.values(res.response.data)[0]);
      }
    });
  };

  useEffect(() => {
    getNotes();
  }, []);

  const handleDeleteNote = async (e, id) => {
    e.preventDefault();
    let res = await delete_note(authAxios, id);
    if (res.status === 204) {
      toast.success('Deleted Successfully');
      await getNotes();
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };
  const handleAddNote = async (e) => {
    e.preventDefault();
    const payload = {
      headline: noteHeadLine,
      content: noteContent,
      customer: id,
      author: user?.user_id,
    };
    // if (!noteHeadLine || !noteContent || !id || !user.user_id) {
    //   toast.error('Invalid Note');
    //   return;
    // }

    let res = await create_note(authAxios, payload);
    if (res.status === 200) {
      toast.success('Created Successfully');
      setNoteHeadLine('');
      setNoteContent('');
      await getNotes();
      setAddNew(false);
    } else {
      toast.error(Object.values(res.response.data)[0]);
    }
  };

  return (
    <div className='notesTab'>
      <div className='notesTabHeader'>
        <button onClick={() => setAddNew((addNew) => !addNew)}>Add New</button>
      </div>
      <div
        className={`note addNoteContainer ${
          addNew ? 'showAddNew' : 'hideAddNew'
        }`}
      >
        <form onSubmit={(e) => handleAddNote(e)}>
          <div className='headline'>
            <input
              required
              type='text'
              name='headline'
              placeholder='Headline'
              value={noteHeadLine}
              onChange={(e) => setNoteHeadLine(e.target.value)}
            />
            <button type='submit'>Save</button>
          </div>
          <div style={{ width: '100%' }}>
            <textarea
              required
              type='text'
              name='content'
              placeholder='Contect'
              value={noteContent}
              onChange={(e) => setNoteContent(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div className='notes'>
        {!notes ? (
          <div>
            <CircularProgress color='primary' />
          </div>
        ) : notes.length === 0 ? (
          <div>No Notes Availavble</div>
        ) : (
          notes.map((note) => (
            <div className='note'>
              <div className='text'>
                <h3>{note.headline}</h3>
                <span>{note.content}</span>
              </div>
              <div
                style={{
                  color: '#d4d4d4',
                  fontSize: '14px',
                  textAlign: 'right',
                }}
              >
                <div className='btns'>
                  <div onClick={(e) => handleDeleteNote(e, note.id)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      strokeWidth={1.5}
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        strokeLinecap='round'
                        strokeLinejoin='round'
                        d='M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0'
                      />
                    </svg>
                  </div>
                </div>
                <div>By: {note.author.username}</div>
                <div>
                  {moment(note?.created_at).format('MM/DD/YYYY HH:MM A')}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
};
